<template>
  <div class="contact-page">
    <div class="page-header">
      <div class="header-layer">
        <div class="container">
          <div class="header-title">
            <h3>Холбоо барих</h3>
            <span class="divider"></span>
            <div class="header-breadcrumb">
              <router-link to="/">
                <span>Эхлэл</span>
                <i class="isax isax-arrow-right-4"></i>
              </router-link>

              <router-link to="/faq" class="current">
                <span>Холбоо барих</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="contact-page-section">
      <div class="container">
        <div class="inner-container">
          <!--Form Column-->
          <div class="form-column">
            <div class="inner-column">
              <!--Contact Form-->
              <div class="contact-form">
                <form method="post" action="sendemail.php" id="contact-form">
                  <div class="row clearfix">
                    <div class="form-group col-md-6 col-sm-6 co-xs-12">
                      <input type="text" name="name" value="" placeholder="Нэр" required>
                    </div>
                    <div class="form-group col-md-6 col-sm-6 co-xs-12">
                      <input type="email" name="email" value="" placeholder="Имэйл" required>
                    </div>
                    <div class="form-group col-md-6 col-sm-6 co-xs-12">
                      <input type="text" name="subject" value="" placeholder="Гарчиг" required>
                    </div>
                    <div class="form-group col-md-12 col-sm-12 co-xs-12">
                      <textarea name="message" placeholder="Агуулга"></textarea>
                    </div>
                    <div class="form-group col-md-12 col-sm-12 co-xs-12">
                      <button type="submit" class="theme-btn btn-style-one">Илгээх</button>
                    </div>
                  </div>
                </form>
              </div>
              <!--End Contact Form-->

            </div>
          </div>

          <!--Info Column-->
          <div class="info-column">
            <div class="inner-column">
              <h2>Холбогдох мэдээлэл</h2>
              <ul class="list-info">
                <li><i class="uil uil-map-marker"></i>Блю Скай тауэр, 4 давхар, № 403, Энхтайвны өргөн чөлөө 17, 1-р
                  хороо, Сүхбаатар дүүрэг, Улаанбаатар хот 14240, Монгол улс.
                </li>
                <li><i class="uil uil-envelope"></i>info@bloombergtv.mn</li>
                <li><i class="uil uil-phone-alt"></i>+976 70102020</li>
              </ul>
              <ul class="social-icon-four">
                <li class="follow">Follow on:</li>
                <li><a href="#"><i class="uil uil-facebook"></i></a></li>
                <li><a href="#"><i class="uil uil-twitter"></i></a></li>
                <li><a href="#"><i class="uil uil-instagram-alt"></i></a></li>
                <li><a href="#"><i class="uil uil-youtube"></i></a></li>
                <li><a href="#"><i class="uil uil-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>